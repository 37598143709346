import * as React from 'react'
import Layout from '../components/layout'
import Container from '../components/container'
import Seo from '../components/seo'

const PrivacyCaliforniaPage = () => (
  <Layout knockoutHeader={false}>
    <Seo
      title='“Do Not Sell My Info” Notice for California Residents'
    />
    <div className='PageHead PageHead--light'>
      <h1 className='PageHead-title'>
        “Do Not Sell My Info” Notice for California Residents
      </h1>
      <hr className='Divider' />
      <p className='text-sm mb-0'>
        Effective: February 11, 2021
      </p>
    </div>
    <div className='Section Section--condensed'>
      <Container size='sm'>
        <div class="LegalText">
          <p>
            There are certain services that you use of ours for which we do not charge. Instead, advertisers may pay us for allowing them to show you advertisements for their products and services online. Like many other online companies, we may use services provided by Google, Facebook, and others to help deliver interest-based ads to you.
          </p>
          <p>
            California law may classify some cases of limited information sharing with advertising partners through the use of these services as a “sale” of personal information from which California residents have the right to opt-out. This is because the companies that provide these services and our advertising partners may collect some information from our users (e.g., cookies, advertising IDs, IP addresses, and usage activity) to help them understand how people interact with advertising content on and off of our services and content, and to serve ads that they think users may want to see based on their online activity.
          </p>
          <p>
            We wanted to take this opportunity to explain your choices regarding how to control the limited information that is shared. You have the ability to restrict this use of cookies and advertising IDs.
          </p>
          <p>
            There are a few different ways you can do this for cookies:
          </p>
          <p>
            You can use your browser’s settings to clear, delete, or prevent cookies. Instructions for how to do this on the different types of browsers can be found <a href="https://www.allaboutcookies.org/manage-cookies">here</a>.
          </p>
          <ul>
            <li>
              We may use Google tools to measure and serve advertisement. Please go to <a href="https://adssettings.google.com/authenticated?hl=en">here</a> to control your advertisement preferences with Google, and further <a href="https://tools.google.com/dlpage/gaoptout?hl=en">here</a> for additional tools to opt-out of personalized ads and cookies from Google.
            </li>
            <li>
              We may use Amazon tools to measure and serve advertisement. Please go <a href="https://www.amazon.com/adprefs">here</a> to control your advertisement preferences with Amazon, and <a href="https://www.amazon.com/b?ie=UTF8&amp;node=5160028011">here</a> to learn more about how Amazon conducts these services.
            </li>
            <li>
              Many of our advertisement partners collect information by using a tag or pixel implemented by a company that is a member of the National Advertising Initiative and/or the Digital Advertising Alliance, both of which participate in opt-outs. These organizations can help you install opt-out “do not track” cookies on your browser, so that member companies that have committed to honoring them will stop serving you interest based-advertisements. You can have these opt-out cookies placed on your browser by going to <a href="http://optout.networkadvertising.org/?c=1">here</a> and <a href="https://www.aboutads.info/choices">here</a>. Please note that if you have configured your browser’s settings to block cookies you may not be able to set the opt-out cookie and may see a message that an “Opt-Out is Blocked by Browser Settings,” or that an “Opt-Out is Temporarily Unavailable.” In addition, if you clear or delete cookies on a particular browser, you will need to reset the opt-out cookie. If you use different computers or browsers, you may need to replicate your choices across those computers and browsers.
            </li>
            <li>
              Additionally, you can also restrict the collection and use of advertising IDs on your mobile device. Instructions for how to do this can be found <a href="https://www.networkadvertising.org/mobile-choice">here</a>. If you use multiple mobile devices, you will need to replicate your selections across these devices.
            </li>
          </ul>
        </div>
      </Container>
    </div>
  </Layout>
)

export default PrivacyCaliforniaPage
